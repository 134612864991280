var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    staticClass: "body-field",
    attrs: { name: "body", label: "body" },
    model: {
      value: _vm.inputText,
      callback: function ($$v) {
        _vm.inputText = $$v
      },
      expression: "inputText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }