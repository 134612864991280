import Config from "@/config";
import { resolve } from "url";

export default {
    en: {
        TITLE_SIGNUP: "Signup",
        SIGNUP_REGISTRATION_TITLE: {
            VACR: "Signup for your Organization",
            USCR: "New U.S. Cyber Range Customers"
        },
        SIGNUP_ORGANIZATION_NAME: "Organization Name",
        SIGNUP_ORGANIZATION_DESCRIPTION: {
            VACR: "Want to register an organization? Please fill out and submit this form and you will be contacted as soon as possible.",
            USCR: "Would you like to have access to the U.S. Cyber Range for your school or organization? Please fill out and submit this form and you will be contacted as soon as possible.",
        },
        SIGNUP_NAME: "Name",
        SIGNUP_COURSE_CODE_ERROR: 'Invalid Code',
        SIGNUP_EMAIL: "Email",
        SIGNUP_YES_BUTTON_TEXT: 'Yes',
        SIGNUP_NO_BUTTON_TEXT: 'No',
        SIGNUP_INSTRUCTOR_VACR: 'Do you teach a cybersecurity class in a public school, college, or university in Virginia?',
        SIGNUP_INSTRUCTOR_VIRGINIA_FACULTY_ONLY_TEXT: `The Virginia Cyber Range is limited to faculty teaching cyber security related classes at Virginia public high schools, colleges, and universities. Please visit the <a href="${Config.CYBER_RANGE_UI_USCR_BASE_URL}">U.S. Cyber Range of Virginia Tech</a> to learn about our national offerings.`,
        SIGNUP_SUBMISSION_COMPLETED: 'Submission Completed!',
        SIGNUP_SUBMISSION_COMPLETED_TEXT: {
            USCR: `Thank you for your inquiry, we will be in touch with you as soon as possible. Feel free to browse our <a href="${resolve(Config.HOMEPAGE_UI_BASE_URL,'courseware')}">courseware repository</a> while you wait.`,
            VACR: `Thank you for signing up. Feel free to browse the <a href="${resolve(Config.HOMEPAGE_UI_BASE_URL,'courseware')}">courseware repository</a> to learn more about our available content.`,
        },
        SIGNUP_BUTTON_TEXT: "Sign up",
        SIGNUP_ORGANIZATION_CODE_INFO: "What is my organization code?",
        SIGNUP_ORGANIZATION_CODE_TOOLTIP: "Each organization has a unique code used to sign up. Please contact an organization admin for your organization-specific code.",

        // Registration
        REGISTRATION_STUDENT_INVITATION_CODE: "Student Sign Up",
        REGISTRATION_STUDENT_MESSAGE: "Are you a student taking a course that requires access to the Cyber Range?  If so, please click the button below to setup your account.",
        REGISTRATION_STUDENT_ACTION_BUTTON: "Join as a Student",
        REGISTRATION_SIGNUP_FINISHED_TITLE:"Thank You",
        REGISTRATION_SIGNUP_FINISHED_MESSAGE:"Your request has been received and will be reviewed by our support team shortly.",
        REGISTRATION_SIGNUP_FORM_INSTRUCTIONS:"Please fill out this form to request an instructor account for <b>{organization}</b>.",
        REGISTRATION_SIGNUP_FORM_TITLE: {
            VACR: "Instructor Sign Up",
            USCR: "Instructor Sign Up"
        },
        REGISTRATION_MONTHLY_NEWSLETTER:"Signup for monthly newsletter",
        REGISTRATION_TEACHER_FORUM:"Signup for the teacher forum",
        REGISTRATION_SIGNUP_CONFIRM_BUTTON:"Confirm",
        REGISTRATION_SIGNUP_CANCEL_BUTTON: 'Cancel',
        REGISTRATION_FIRST_NAME:"First Name",
        REGISTRATION_LAST_NAME:"Last Name",
        REGISTRATION_NEWSLETTER_ERROR: "This email address appears to be invalid, please use your organization's institutional email address.",

        // Invitation
        INVITATION_ACCOUNT_REQUEST_EMAIL_TITLE: 'Institutional Email Address',
        INVITATION_JOB_TITLE: 'Job Title',
        INVITATION_ACCOUNT_REQUEST_INVALID_CODE_MESSAGE: 'Organization code is invalid.',
        INVITATION_ACCOUNT_REQUEST_ORGANIZATION_CODE: 'Organization Code',

        // General
        NEXT: 'Next',
        ERROR_PREFIX: 'Error:',
    }
};
