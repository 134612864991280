




import Vue from 'vue';
import Component from 'vue-class-component';
import { Model, Watch } from 'vue-property-decorator';

@Component({})
export default class Honeypot extends Vue {
    @Model('update:modelValue') value: string;

    inputText: string = "";

    @Watch('inputText')
    onInputTextChanged()
    {
        this.$emit('update:modelValue', this.inputText);
    }
}

