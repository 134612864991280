































import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";
import StoreGetter from "@interfaces/storeGetter";
import Config from "../../config";
import UscrOrganizationSignUp from './uscr/UscrOrganizationSignUp.vue';
import StudentRegistration from './vacr/StudentRegistration.vue';
import UscrInstructorSignUp from './uscr/UscrInstructorSignUp.vue';
import VacrInstructorSignUp from './vacr/VacrInstructorSignUp.vue';
import ErrorDialog from "@utils/ErrorDialog.vue";
import TitleStrings from "@/entities/strings/definitions/signupStrings";
import { Watch } from "vue-property-decorator";

@Component({
    components: { StudentRegistration, UscrOrganizationSignUp, UscrInstructorSignUp, VacrInstructorSignUp, ErrorDialog },
    metaInfo() {
        return { title: `${TitleStrings.en.TITLE_SIGNUP} | ${Config.APP_TITLE}` };
    }
})
export default class AccountRequest extends Vue {
    submittingLeft: boolean = false;
    submittingRight: boolean = false;
    completedLeft: boolean = false;
    completedRight: boolean = false;
    @Getter(StoreGetter.GetErrorMessage) error: String;
    showErrorDialog: boolean = false;

    @Watch("error")
    onErrorChanged(val: string, oldVal: string) {
        if (val !== undefined) {
            this.showErrorDialog = true;
        }
    }

    toggleShowErrorDialog() {
        this.showErrorDialog = !this.showErrorDialog;
    }

    get isUSCR(): boolean {
        return Config.BASE_DOMAIN === 'USCR';
    }

    get hideLeftSide(): boolean {
        return this.submittingRight || this.completedRight;
    }

    get hideRightSide(): boolean {
        return this.submittingLeft || this.completedLeft;
    }
}
