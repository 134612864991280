var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "div",
                { attrs: { "aria-live": "polite" } },
                [
                  _vm.finished
                    ? [
                        _c("v-flex", { attrs: { xs12: "", "pb-3": "" } }, [
                          _c("h1", { staticClass: "headline" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("REGISTRATION_SIGNUP_FINISHED_TITLE")
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.finished
                              ? [
                                  _c("p", {
                                    staticClass: "mt-4 mb-4",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "SIGNUP_SUBMISSION_COMPLETED_TEXT"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.finished
                ? [
                    _c("v-flex", { attrs: { xs12: "", "pb-3": "" } }, [
                      _c("h1", { staticClass: "headline" }, [
                        _vm._v(
                          _vm._s(_vm.$t("REGISTRATION_SIGNUP_FORM_TITLE"))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        !_vm.validated
                          ? [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.$t(
                                              "INVITATION_ACCOUNT_REQUEST_ORGANIZATION_CODE"
                                            ) + "*",
                                          disabled: _vm.loading,
                                          "error-messages":
                                            !_vm.validated &&
                                            !_vm.attemptedValidation
                                              ? ""
                                              : _vm.$t(
                                                  "INVITATION_ACCOUNT_REQUEST_INVALID_CODE_MESSAGE"
                                                ),
                                          required: "",
                                        },
                                        on: {
                                          input: function ($event) {
                                            _vm.attemptedValidation = false
                                          },
                                          change: function ($event) {
                                            return _vm.validateOrganizationCode()
                                          },
                                        },
                                        model: {
                                          value: _vm.code,
                                          callback: function ($$v) {
                                            _vm.code = $$v
                                          },
                                          expression: "code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _vm.mailChimpError
                                ? _c("ErrorDialog", {
                                    attrs: {
                                      error:
                                        "" +
                                        _vm.$t("REGISTRATION_NEWSLETTER_ERROR"),
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        _vm.mailChimpError = false
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "AccessibleForm",
                                {
                                  attrs: {
                                    "submit-function": _vm.confirm,
                                    "form-instruction": "",
                                  },
                                },
                                [
                                  _c("p", {
                                    staticClass: "mt-3 mb-3 subheading",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "REGISTRATION_SIGNUP_FORM_INSTRUCTIONS",
                                          {
                                            organization:
                                              _vm.selectedOrganization
                                                .organizationName,
                                          }
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          "required|max:" + _vm.maxFieldLength,
                                        expression:
                                          "`required|max:${maxFieldLength}`",
                                      },
                                    ],
                                    attrs: {
                                      label:
                                        _vm.$t("REGISTRATION_FIRST_NAME") + "*",
                                      required: "",
                                      "error-messages":
                                        _vm.errors.collect("first name"),
                                      disabled: _vm.loading,
                                      "data-vv-name": "first name",
                                      autocomplete: "given-name",
                                    },
                                    model: {
                                      value: _vm.firstName,
                                      callback: function ($$v) {
                                        _vm.firstName = $$v
                                      },
                                      expression: "firstName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          "required|max:" + _vm.maxFieldLength,
                                        expression:
                                          "`required|max:${maxFieldLength}`",
                                      },
                                    ],
                                    attrs: {
                                      label:
                                        _vm.$t("REGISTRATION_LAST_NAME") + "*",
                                      required: "",
                                      "error-messages":
                                        _vm.errors.collect("last name"),
                                      disabled: _vm.loading,
                                      "data-vv-name": "last name",
                                      autocomplete: "family-name",
                                    },
                                    model: {
                                      value: _vm.lastName,
                                      callback: function ($$v) {
                                        _vm.lastName = $$v
                                      },
                                      expression: "lastName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          "required|email|max:" +
                                          _vm.maxFieldLength,
                                        expression:
                                          "`required|email|max:${maxFieldLength}`",
                                      },
                                    ],
                                    attrs: {
                                      label:
                                        _vm.$t(
                                          "INVITATION_ACCOUNT_REQUEST_EMAIL_TITLE"
                                        ) + "*",
                                      required: "",
                                      "error-messages":
                                        _vm.errors.collect("email"),
                                      disabled: _vm.loading,
                                      "data-vv-name": "email",
                                      autocomplete: "email",
                                    },
                                    model: {
                                      value: _vm.invitation.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.invitation, "email", $$v)
                                      },
                                      expression: "invitation.email",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value:
                                          "required|max:" + _vm.maxFieldLength,
                                        expression:
                                          "`required|max:${maxFieldLength}`",
                                      },
                                    ],
                                    attrs: {
                                      label:
                                        _vm.$t("INVITATION_JOB_TITLE") + "*",
                                      disabled: _vm.loading,
                                      required: "",
                                      "error-messages":
                                        _vm.errors.collect("title"),
                                      "data-vv-name": "title",
                                    },
                                    model: {
                                      value: _vm.invitation.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.invitation, "title", $$v)
                                      },
                                      expression: "invitation.title",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.organizationBusinessUnits.length > 1
                                    ? _c("accessible-combobox", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: !_vm.creatingBusinessUnit
                                              ? "required"
                                              : "",
                                            expression:
                                              "!creatingBusinessUnit? `required`: ''",
                                          },
                                        ],
                                        attrs: {
                                          items: _vm.organizationBusinessUnits,
                                          "item-text": "displayText",
                                          "item-value": "id",
                                          label:
                                            _vm.businessUnitDisplayName(
                                              _vm.selectedOrganization
                                            ) + "*",
                                          "error-messages":
                                            _vm.businessUnitSelectorErrorMessage(
                                              "businessUnitDisplaySelector"
                                            ),
                                          disabled: _vm.loading,
                                          required: !_vm.creatingBusinessUnit,
                                          "data-vv-name":
                                            "businessUnitDisplaySelector",
                                        },
                                        model: {
                                          value: _vm.selectedSchool,
                                          callback: function ($$v) {
                                            _vm.selectedSchool = $$v
                                          },
                                          expression: "selectedSchool",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.creatingBusinessUnit
                                    ? _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required|max:" +
                                              _vm.maxFieldLength,
                                            expression:
                                              "`required|max:${maxFieldLength}`",
                                          },
                                        ],
                                        attrs: {
                                          label:
                                            _vm.businessUnitDisplayName(
                                              _vm.selectedOrganization
                                            ) + "*",
                                          disabled: _vm.loading,
                                          required: "",
                                          "error-messages": _vm.errors.collect(
                                            _vm
                                              .businessUnitDisplayName(
                                                _vm.selectedOrganization
                                              )
                                              .toLowerCase()
                                          ),
                                          "data-vv-name": _vm
                                            .businessUnitDisplayName(
                                              _vm.selectedOrganization
                                            )
                                            .toLowerCase(),
                                        },
                                        model: {
                                          value: _vm.invitation.businessUnit,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invitation,
                                              "businessUnit",
                                              $$v
                                            )
                                          },
                                          expression: "invitation.businessUnit",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.creatingBusinessUnit
                                    ? _c("accessible-combobox", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              _vm.creatingBusinessUnit &&
                                              !_vm.creatingBusinessUnitRegion
                                                ? "required"
                                                : "",
                                            expression:
                                              "creatingBusinessUnit && !creatingBusinessUnitRegion? `required`: ''",
                                          },
                                        ],
                                        attrs: {
                                          label:
                                            _vm.businessUnitDisplayRegion(
                                              _vm.selectedOrganization
                                            ) + "*",
                                          items: _vm.organizationRegions,
                                          disabled: _vm.loading,
                                          "error-messages":
                                            _vm.businessUnitSelectorErrorMessage(
                                              "businessUnitRegionSelector"
                                            ),
                                          required:
                                            _vm.creatingBusinessUnit &&
                                            !_vm.creatingBusinessUnitRegion,
                                          "data-vv-name":
                                            "businessUnitRegionSelector",
                                        },
                                        model: {
                                          value: _vm.selectedBusinessRegion,
                                          callback: function ($$v) {
                                            _vm.selectedBusinessRegion = $$v
                                          },
                                          expression: "selectedBusinessRegion",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.creatingBusinessUnit &&
                                  _vm.creatingBusinessUnitRegion
                                    ? _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              "required|max:" +
                                              _vm.maxFieldLength,
                                            expression:
                                              "`required|max:${maxFieldLength}`",
                                          },
                                        ],
                                        attrs: {
                                          label:
                                            _vm.businessUnitDisplayRegion(
                                              _vm.selectedOrganization
                                            ) + "*",
                                          required: "",
                                          disabled: _vm.loading,
                                          "error-messages":
                                            _vm.businessUnitErrorMessage(
                                              "businessUnitDisplayRegion"
                                            ),
                                          "data-vv-name":
                                            "'businessUnitDisplayRegion'",
                                        },
                                        model: {
                                          value: _vm.invitation.businessRegion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invitation,
                                              "businessRegion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invitation.businessRegion",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.creatingBusinessUnit
                                    ? _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "`required`",
                                          },
                                        ],
                                        attrs: {
                                          label:
                                            _vm.businessUnitDisplayCategory(
                                              _vm.selectedOrganization
                                            ) + "*",
                                          items: _vm.businessUnitCategories,
                                          disabled: _vm.loading,
                                          required: "",
                                          "item-text": "key",
                                          "item-value": "value",
                                          "error-messages":
                                            _vm.businessUnitErrorMessage(
                                              "businessUnitDisplayCategory"
                                            ),
                                          "data-vv-name":
                                            "businessUnitDisplayCategory",
                                        },
                                        model: {
                                          value:
                                            _vm.invitation.businessCategory,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invitation,
                                              "businessCategory",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invitation.businessCategory",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-checkbox", {
                                    staticClass: "mt-3 pt-0",
                                    attrs: {
                                      disabled: _vm.loading,
                                      label: "Signup for monthly newsletter",
                                    },
                                    model: {
                                      value: _vm.newsLetterChecked,
                                      callback: function ($$v) {
                                        _vm.newsLetterChecked = $$v
                                      },
                                      expression: "newsLetterChecked",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("Honeypot", {
                                    model: {
                                      value: _vm.honeypotFieldValue,
                                      callback: function ($$v) {
                                        _vm.honeypotFieldValue = $$v
                                      },
                                      expression: "honeypotFieldValue",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "pt-3",
                                          attrs: { to: "/" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REGISTRATION_ALREADY_REGISTERED"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "elevation-0",
                                          attrs: {
                                            disabled: _vm.loading,
                                            dark: !_vm.loading,
                                            color: "cancel",
                                            "aria-label": _vm.$t(
                                              "REGISTRATION_SIGNUP_CANCEL_BUTTON"
                                            ),
                                          },
                                          on: { click: _vm.cancel },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REGISTRATION_SIGNUP_CANCEL_BUTTON"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "elevation-0 ml-2",
                                          attrs: {
                                            type: "submit",
                                            disabled: _vm.loading,
                                            dark: !_vm.loading,
                                            loading: _vm.loading,
                                            color: "confirm",
                                            "aria-label": _vm.$t(
                                              "REGISTRATION_SIGNUP_CONFIRM_BUTTON"
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "REGISTRATION_SIGNUP_CONFIRM_BUTTON"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      !_vm.validated
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "", md8: "" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        {
                                          staticClass: "tooltipClass",
                                          staticStyle: {
                                            "line-height": "36px",
                                            height: "36px",
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "SIGNUP_ORGANIZATION_CODE_INFO"
                                              )
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            326094104
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("SIGNUP_ORGANIZATION_CODE_TOOLTIP"))
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-xs-center elevation-0 ma-0",
                      attrs: { color: "primary", "aria-label": _vm.$t("NEXT") },
                      on: {
                        click: function ($event) {
                          return _vm.validateOrganizationCode()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.validatingCode ? "" : _vm.$t("NEXT")) +
                          "\n                    "
                      ),
                      _vm.validatingCode
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }